import { Injectable } from "@angular/core";
import { LocalStorageService } from "web-frontend-component-library/services";
import { AuthService } from "@core/services/auth/auth.service";
import { SystemParamsApiService } from "@core/services/api/system-params-api.service";
import { ISystemParam, ISystemParams } from "@core/interfaces/system-params";
import { BehaviorSubject, Observable } from "rxjs";
import {EDM_SYSTEM_PARAMETER_NAME, PUBLIC_SYSTEM_PARAMS_KEY, SYSTEM_PARAMS_KEY} from "@app/app.enums";
import { SYSTEM_PARAMETER_NAME } from "web-frontend-component-library/enums";

@Injectable({
  providedIn: 'root'
})
export class SystemParamsService {

  private systemParams$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private publicSystemParams$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private paramKeys: string[] = Object.keys(SYSTEM_PARAMETER_NAME).map(k => SYSTEM_PARAMETER_NAME[k]);
  private systemParamKeys: string[] = Object.keys(EDM_SYSTEM_PARAMETER_NAME).map(k => EDM_SYSTEM_PARAMETER_NAME[k]);

  constructor(
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private systemParamsApiService: SystemParamsApiService
  ) {
    this.systemParams$.next(this.localStorageService.getObjectByName(SYSTEM_PARAMS_KEY));
    this.publicSystemParams$.next(this.localStorageService.getObjectByName(PUBLIC_SYSTEM_PARAMS_KEY));
    if (!this.publicSystemParams$.getValue()) {
      this.getPublicSystemParameters();
    }
    if (!this.systemParams$.getValue() && this.authService.isAuthorized()) {
      this.getSystemParameters();
    }
  }

  public clearSystemParameters() {
    this.localStorageService.setObjectByName(SYSTEM_PARAMS_KEY, null);
    this.localStorageService.setObjectByName(PUBLIC_SYSTEM_PARAMS_KEY, null);
  }

  public getSystemParams(): Observable<any> {
    return this.systemParams$.asObservable();
  }

  public getPublicSystemParams(): Observable<any> {
    return this.publicSystemParams$.asObservable();
  }

  public setPublicSystemParams(systemParams: ISystemParams) {
    const systemParamsArr = systemParams.systemParameters;
    let result = {};
    systemParamsArr.forEach((param: ISystemParam) => {
      if (param.name === SYSTEM_PARAMETER_NAME.SUPPORT_PHONE) {
        result[param.name] = this.preparePhone(param.value);
      } else {
        result[param.name] = param.value;
      }
      if (param.name === SYSTEM_PARAMETER_NAME.AUTH_URL) {
        this.localStorageService.setObjectByName(SYSTEM_PARAMETER_NAME.AUTH_URL, param.value);
      }
    });
    this.localStorageService.setObjectByName(PUBLIC_SYSTEM_PARAMS_KEY, result);
    this.publicSystemParams$.next(result);
  }

  private getSystemParameters() {
    this.systemParamsApiService.getSystemParams([...this.paramKeys, ...this.systemParamKeys])
      .subscribe((systemParams: ISystemParams) => {
        const systemParamsArr = systemParams.systemParameters;
        let result = {};
        systemParamsArr.forEach((param: ISystemParam) => {
          result[param.name] = param.value;
        });
        this.localStorageService.setObjectByName(SYSTEM_PARAMS_KEY, result);
        this.systemParams$.next(result);
      });
  }

  private getPublicSystemParameters() {
    this.systemParamsApiService.getPublicSystemParams()
      .subscribe((systemParams: ISystemParams) => {
        this.setPublicSystemParams(systemParams);
      });
  }

  private preparePhone(phone: string): string {
    const code_phone = phone.substr(0, 3);
    const first_part_phone = phone.substr(3, 3);
    const second_part_phone = phone.substr(6, 2);
    const third_part_phone = phone.substr(8, 2);
    return `+7 (${code_phone}) ${first_part_phone}-${second_part_phone}-${third_part_phone}`;
  }

  public updateSystemParams() {
    this.getPublicSystemParameters();
    this.getSystemParameters();
  }

  public updatePublicSystemParams() {
    this.getPublicSystemParameters();
  }

}
