import { ACCESS_TREE_OBJECT_TYPE } from "web-frontend-component-library/enums";
import { IAccessTreeItem } from "web-frontend-component-library/interfaces";

/** Регулярное выражения для проверки корректности IP адреса */
export const REGEXP_IP = /([0-9]{1,3}[\.]){3}[0-9]{1,3}/;
export const code_pattern = /[A-Z_0-9]/;
export const ip4regexp: RegExp =
  /^(?!\.)((^|\.)([1-9]?\d|1\d\d|2(5[0-5]|[0-4]\d))){4}$/;
export const ip4withportregexp: RegExp =
  /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}(:[0-9]{4})*$/;
export const ip6regexp: RegExp =
  /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;
export const hostnameregexp: RegExp = /^([a-zA-Z0-9.-])*(:[0-9]{4})*$/;
export const urlregexp: RegExp = /(https?:\/\/[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

export const ACCESS_OBJECTS_KEY = "Edm-AccessObjects";
export const ACCESS_RIGHTS_KEY = "Edm-AccessRights";
export const USER_MENU_KEY = "Edm-UserMenuKey";
export const MENU_OPENED_KEY = "Edm-MenuOpenedKey";
export const USER_INFO_KEY = "Edm-UserInfo";
export const REDIRECT_TO_KEY = "Edm-RedirectTo";
export const AUTH_KEY = "Edm-Auth";
export const SYSTEM_PARAMS_KEY = "Edm-SystemParams";
export const PUBLIC_SYSTEM_PARAMS_KEY = "Edm-PublicSystemParams";

export enum EDM_SYSTEM_PARAMETER_NAME {
  ORGANIZATION_NAME = "ORGANIZATION_NAME",
  AGENT_INSTALLERS_PAGE_PUBLIC = "AGENT_INSTALLERS_PAGE_PUBLIC",
}

export const MENU_URLS = {
  g_Catalog: "/nsi/dictionaries",
  g_Role: "/admin/settings-access/roles",
  g_User: "/admin/settings-access/users",
  g_AutomaticRoles: "/admin/settings-access/automatic-roles",
  g_System: "/admin/parameters/system-parameters",
  g_TaskSchedule: "/admin/parameters/task-schedule",
  g_EventLog: "/admin/monitoring/event-log",
  g_HelpSessionLog: "/admin/monitoring/remote-assistance-session-log",
  g_WebProxy: "/admin/parameters/web-proxy",
  g_Profile: "/personal-area/profile",
  g_PEventLog: "/personal-area/monitoring/event-log",
  g_ListARM: "/arm/arms",
  g_GroupARM: "/arm/arm-groups",
  g_SampleTask: "/task/task-samples",
  g_StandartSampleTask: "/task/standart-task-samples",
  g_JournalTaskARM: "/task/arm-task-journal",
  g_TaskURM: "/task/task-urm",
  g_EventLogARM: "/arm/arm-event-log",
  g_Query: "/arm/view",
  g_FileStorage: "/admin/components/file-storage",
  g_TaskScenarioDiagram: "/admin/task-scenario-diagram",
  g_ArmProperties: "/admin/parameters/arm-properties",
  g_AgentInstaller: "/admin/components/agent-installers",
  g_AgentCmdQueue: "/admin/monitoring/agent-cmd-queue",
  g_Security: "/admin/parameters/security",
  g_SystemInfo: "/admin/about-system",
  g_InventoryHistory: "/admin/parameters/inventory-history-settings",
};

export const MENU_ICONS = {
  m_NSI: "icon-menu_nsi",
  m_Admin: "icon-menu_admin",
  m_PersPage: "icon-menu_personal_page",
  m_ARM: "icon-menu_arm",
  m_Task: "icon-menu_contracts",
};

export const MENU_OPENED_URLS = ["/main", "/test"];

export const MENU_ADDITIONAL: IAccessTreeItem[] = [
  {
    id: "m_PersPage",
    code: "m_PersPage",
    name: "Личный кабинет",
    objectType: ACCESS_TREE_OBJECT_TYPE.MENU,
    parentId: null,
    sortOrder: 1000,
  },
  {
    id: "g_Profile",
    code: "g_Profile",
    name: "Профиль",
    objectType: ACCESS_TREE_OBJECT_TYPE.GRID,
    parentId: "m_PersPage",
    sortOrder: 10,
  },
  {
    id: "m_PEventsLog",
    code: "m_PEventsLog",
    name: "Журналы действий",
    objectType: ACCESS_TREE_OBJECT_TYPE.MENU,
    parentId: "m_PersPage",
    sortOrder: 20,
  },
  {
    id: "g_PEventLog",
    code: "g_PEventLog",
    name: "События",
    objectType: ACCESS_TREE_OBJECT_TYPE.GRID,
    parentId: "m_PEventsLog",
    sortOrder: 10,
  },
];

export enum GRID_ID {
  "ADMIN.USERS" = "Edm-admin.users",
  "ADMIN.ROLES" = "Edm-admin.roles",
  "ADMIN.AUTOMATIC_ROLES" = "Edm-admin.automatic-roles",
  "ADMIN.SYSTEM_PARAMETERS" = "Edm-admin.system_parameters",
  "ADMIN.WEB_PROXY" = "Edm-admin.web_proxy",
  "ADMIN.WEB_PROXY_JOURNAL" = "Edm-admin.web_proxy_journal",
  "ADMIN.TASK_SCHEDULE" = "Edm-admin.task_schedule",
  "EVENT_LOG" = "Edm.event_log",
  "REMOTE_ASSISTANCE_SESSIONS_LOG" = "Edm.remote-assistance_sessions_log",
  "ADMIN.AGENT_COMMAND_QUEUE" = "Edm.agent-command-queue",
  "ADMIN.AGENT_INSTALLERS" = "Edm.agent-installers",
  "ADMIN.FILE_STORAGE" = "Edm-admin.file_storage",
  "ADMIN.TASK_SCENARIO_DIAGRAM" = "Edm-admin.task_scenario_diagram",
  "ADMIN.SECURITY.SERVER_CERTIFICATE" = "Edm-admin.security.server_sertificate",
  "ADMIN.SECURITY.AGENT_CERTIFICATE" = "Edm-admin.security.agent_sertificate",
  "PERSONAL_AREA.MONITORING.EVENT_LOG_JOURNAL" = "Edm.personal_area.monitoring.event_log_journal",
  "ARMS" = "Edm-arm.arms",
  "ARMS.PO_LIST" = "Edm-arm.arms.po_list",
  "ARMS.INVENTORY_SERVICE" = "Edm-arm.arms.inventory_service",
  "ARMS.INVENTORY_TASK_SCHEDULE" = "Edm-arm.arms.inventory_task_schedule",
  "ARMS.INVENTORY_PROCESS" = "Edm-arm.arms.inventory_process",
  "ARMS.INVENTORY_LOCAL_GROUP" = "Edm-arm.arms.inventory_local_group",
  "ARMS.INVENTORY_LOCAL_USER" = "Edm-arm.arms.inventory_local_user",
  "ARMS.INVENTORY_HISTORY" = "Edm-arm.arms.inventory_history",
  "ARMS.TASK_JOURNAL" = "Edm-arm.arms.task_journal",
  "ARMS.CERTIFICATES" = "Edm-arm.arms.certificates",
  "ARMS.STATE_LOG" = "Edm-arm.arms.state_log",
  "ARMS.EVENT_LOG" = "Edm-arm.arms.event_log",
  "ARMS.ARM_EVENT_LOG" = "Edm-arm.arms.arm_event_log",
  "ARMS.VIEW.DEVICES" = "Edm-arm.view.devices",
  "ARM_GROUPS" = "Edm-arm.arm_groups",
  "ARM_GROUPS.ARM_LIST" = "Edm-arm.arm_groups.arm_list",
  "ARM_GROUPS.USER_LIST" = "Edm-arm.arm_groups.user_list",
  "TASK_SAMPLES" = "Edm-arm.task_samples",
  "STANDART_TASK_SAMPLES" = "Edm-arm.standart_task_samples",
  "ARM_TASK_JOURNAL" = "Edm-arm.arm_task_journal",
  "ARM_EVENT_LOG" = "Edm-arm.arm_event_log",
  "ARM_REMOTE_SESSION_LOG" = "Edm-arm.arm_remote_session_log",
  "ADMIN.ARM_PROPERTIES" = "Edm-admin.arm_properties",
  "ADMIN.ARM_PROPERTIES.INVENTORY.WINDOWS_KEYS" = "Edm-admin.arm_properties.inventory.windows_keys",
  "ADMIN.DEVICE_EVENT_COLLECTION" = "Edm-admin.device_event_collection",
  "ADMIN.DEVICE_EVENT_COLLECTION.DEVICES" = "Edm-admin.device_event_collection.devices",
  "ADMIN.ABOUT_SYSTEM.SERVICES" = "Edm-admin.about_system.services",
  "TASK_URM" = "Edm-arm.task_urm",
  "TASK_URM.GROUPS" = "Edm-arm.task_urm.groups",
  "TASK_URM.DEVICES" = "Edm-arm.task_urm.devices",
  "TASK_URM.EVENT_LOG" = "Edm-arm.task_urm.event_log",
  "TASK_URM.TASK_JOURNAL" = "Edm-arm.task_urm.task_journal",
  "TASK_URM.TASK_JOURNAL.DETAIL" = "Edm-arm.task_urm.task_journal.detail",
}

export enum USER_ACCESS {
  "ADMIN" = "m_Admin",
  // Роли
  "ROLE" = "g_Role",
  "ROLE.card" = "c_Role",
  "ROLE.card_add" = "c_Role.cf_add",
  "ROLE.card_edit" = "c_Role.cf_edit",
  "ROLE.card_del" = "c_Role.cf_del",
  // Пользователи
  "USER" = "g_User",
  "USER.card" = "c_User",
  "USER.card_edit" = "c_User.cf_edit",
  // Автоматическое назначение ролей
  "AUTOMATIC_ROLES" = "g_AutomaticRoles",
  "AUTOMATIC_ROLES.CARD" = "c_AutomaticRole",
  "AUTOMATIC_ROLES.CARD.ADD" = "c_AutomaticRole.cf_Add",
  "AUTOMATIC_ROLES.CARD.EDIT" = "c_AutomaticRole.cf_Edit",
  "AUTOMATIC_ROLES.CARD.DELETE" = "c_AutomaticRole.cf_Delete",
  // Системные параметры
  "SYSTEM" = "g_System",
  "SYSTEM.card" = "c_System",
  "SYSTEM.card_edit" = "c_System.cf_edit",
  // Расписание запуска задач
  "TASKSCHEDULE" = "g_TaskSchedule",
  "TASKSCHEDULE.card" = "c_TaskSchedule",
  "TASKSCHEDULE.card_edit" = "c_TaskSchedule.cf_Edit",
  // Общий журнал событий
  "EVENT_LOG" = "g_EventLog",
  // Журнал сеансов удаленного помощника
  "REMOTE_ASSISTANCE_SESSION_LOG" = "g_HelpSessionLog",
  // Очередь команд агенту
  "AGENT_COMMAND_QUEUE" = "g_AgentCmdQueue",
  // Очередь команд агенту. Отмена
  "AGENT_COMMAND_QUEUE.CANCEL" = "g_AgentCmdQueue.gf_CmdCancel",
  // Установщики агентов
  "AGENT_INSTALLERS" = "g_AgentInstaller",
  // Карточка установщика агентов
  "AGENT_INSTALLERS.CARD" = "c_AgentInstaller",
  // Карточка установщика агентов. Добавить
  "AGENT_INSTALLERS.CARD.ADD" = "c_AgentInstaller.cf_Add",
  // Карточка установщика агентов. Изменить
  "AGENT_INSTALLERS.CARD.EDIT" = "c_AgentInstaller.cf_Update",
  // Карточка установщика агентов. Удалить
  "AGENT_INSTALLERS.CARD.DELETE" = "c_AgentInstaller.cf_Delete",
  // Карточка установщика агентов. В архив
  "AGENT_INSTALLERS.CARD.ARCHIVE" = "c_AgentInstaller.cf_Archive",
  // Карточка установщика агентов. Собрать
  "AGENT_INSTALLERS.CARD.BUILD" = "c_AgentInstaller.cf_Build",
  // Настройки истории инвентаризации
  "ADMIN.INVENTORY_HISTORY_SETTINGS" = "g_InventoryHistory",
  // НСИ
  "NSI_CATALOG" = "g_Catalog",
  // Веб прокси
  "ADMIN.WEB_PROXY" = "g_WebProxy",
  "ADMIN.WEB_PROXY.CARD" = "c_WebProxy",
  "ADMIN.WEB_PROXY.CARD.JOURNAL" = "c_WebProxy.ct_Journal",
  "ADMIN.WEB_PROXY.CARD.ADD" = "c_WebProxy.cf_add",
  "ADMIN.WEB_PROXY.CARD.EDIT" = "c_WebProxy.cf_edit",
  "ADMIN.WEB_PROXY.CARD.DELETE" = "c_WebProxy.cf_delete",
  "ADMIN.WEB_PROXY.CARD.ADD_ROLE" = "c_WebProxy.cf_add_role",
  "ADMIN.WEB_PROXY.CARD.DELETE_ROLE" = "c_WebProxy.cf_delete_role",
  // Хранилище файлов
  "ADMIN.FILE_STORAGE" = "g_FileStorage",
  // Схема сценариев задач для АРМ
  "ADMIN.TASK_SCENARIO" = "g_TaskScenarioDiagram",
  "ADMIN.TASK_SCENARIO.card" = "c_TaskScenarioDiagram",
  "ADMIN.TASK_SCENARIO.card.add" = "c_TaskScenarioDiagram.cf_Add",
  "ADMIN.TASK_SCENARIO.card.edit" = "c_TaskScenarioDiagram.cf_Edit",
  "ADMIN.TASK_SCENARIO.card.delete" = "c_TaskScenarioDiagram.cf_Delete",

  "ARM" = "m_ARM",
  // Список АРМ
  "ARM_LIST" = "g_ListARM",
  "ARM_LIST.card" = "c_ARM",
  "ARM_LIST.card.po_list" = "c_ARM.ct_POList",
  "ARM_LIST.card.inventory" = "c_ARM.ct_Inventory",
  "ARM_LIST.card.inventory.reload" = "c_ARM.cf_InventoryReload",
  "ARM_LIST.card.inventory.reload_branch" = "c_ARM.cf_InventoryUpdate",
  "ARM_LIST.card.task_journal" = "c_ARM.ct_JournalTask",
  "ARM_LIST.card.journal" = "c_ARM.ct_Journal",
  "ARM_LIST.card.edit" = "c_ARM.cf_Edit",
  "ARM_LIST.card.archive" = "c_ARM.cf_Archive",
  "ARM_LIST.card.block" = "c_ARM.cf_Block",
  "ARM_LIST.card.active" = "c_ARM.cf_Active",
  "ARM_LIST.card.add_task" = "c_ARM.cf_AddTask",
  "ARM_LIST.card.cancel_task" = "c_ARM.cf_CancelTask",
  "ARM_LIST.card.repeat_task" = "c_ARM.cf_RepeatTask",
  "ARM_LIST.card.remote_desktop" = "c_ARM.cf_RemoteDesktop",
  "ARM_LIST.card.silent-remote_desktop" = "c_ARM.cf_RemoteDesktopSilentMode",
  // Группа АРМ
  "ARM_GROUP" = "g_GroupARM",
  "ARM_GROUP.card" = "c_GroupARM",
  "ARM_GROUP.card.add" = "c_GroupARM.cf_Add",
  "ARM_GROUP.card.edit" = "c_GroupARM.cf_Edit",
  "ARM_GROUP.card.delete" = "c_GroupARM.cf_Delete",
  "ARM_GROUP.card.add_arm" = "c_GroupARM.cf_AddGroup",
  "ARM_GROUP.card.delete_arm" = "c_GroupARM.cf_DeleteGroup",
  // Представления
  'ARM_VIEW' = 'g_Query',
  'ARM_VIEW.CARD' = 'c_Query',
  'ARM_VIEW.CARD.ADD_COMMON' = 'c_Query.cf_AddGeneral',
  'ARM_VIEW.CARD.ADD_PRIVATE' = 'c_Query.cf_AddPersonal',
  'ARM_VIEW.CARD.EDIT' = 'c_Query.cf_Edit',
  'ARM_VIEW.CARD.DELETE' = 'c_Query.cf_Delete',
  'ARM_VIEW.CARD.MAKE_COMMON' = 'c_Query.cf_MakeGeneral',
  'ARM_VIEW.CARD.COPY_PRIVATE' = 'c_Query.cf_CopyPersonal',
  'ARM_VIEW.CARD.UPDATE_LIST' = 'c_Query.cf_UpdateList',
  'ARM_VIEW.CARD.ADD_DEVICE' = 'c_Query.cf_AddDevice',
  "TASK" = "m_Task",
  // Пользовательские шаблоны
  "TASK_SAMPLE" = "g_SampleTask",
  "TASK_SAMPLE.card" = "c_SampleTask",
  "TASK_SAMPLE.card.add" = "c_SampleTask.cf_Add",
  "TASK_SAMPLE.card.edit" = "c_SampleTask.cf_Edit",
  "TASK_SAMPLE.card.delete" = "c_SampleTask.cf_Delete",
  "TASK_SAMPLE.card.archive" = "c_SampleTask.cf_Archive",
  "TASK_SAMPLE.card.restore" = "c_SampleTask.cf_Draft",
  "TASK_SAMPLE.card.active" = "c_SampleTask.cf_Active",
  // Стандартные шаблоны
  "STANDART_TASK_SAMPLE" = "g_StandartSampleTask",
  "STANDART_TASK_SAMPLE.card" = "c_StandartSampleTask",
  "STANDART_TASK_SAMPLE.card.add" = "c_StandartSampleTask.cf_Add",
  "STANDART_TASK_SAMPLE.card.edit" = "c_StandartSampleTask.cf_Edit",
  "STANDART_TASK_SAMPLE.card.delete" = "c_StandartSampleTask.cf_Delete",
  "STANDART_TASK_SAMPLE.card.archive" = "c_StandartSampleTask.cf_Archive",
  "STANDART_TASK_SAMPLE.card.restore" = "c_StandartSampleTask.cf_Draft",
  "STANDART_TASK_SAMPLE.card.active" = "c_StandartSampleTask.cf_Active",
  // Журнал запусков задач
  "ARM_TASK_JOURNAL" = "g_JournalTaskARM",
  // Задачи для УРМ
  "TASK_URM" = "g_TaskURM",
  "TASK_URM.card" = "c_TaskURM",
  "TASK_URM.card.test" = "c_TaskURM.ct_Test",
  "TASK_URM.card.devices_group" = "c_TaskURM.ct_DevicesGroup",
  "TASK_URM.card.event_log" = "c_TaskURM.ct_JournalEvent",
  "TASK_URM.card.add" = "c_TaskURM.cf_AddTask",
  "TASK_URM.card.edit" = "c_TaskURM.cf_EditTask",
  "TASK_URM.card.delete" = "c_TaskURM.cf_DeleteTask",
  "TASK_URM.card.active" = "c_TaskURM.cf_Active",
  "TASK_URM.card.inactive" = "c_TaskURM.cf_InActive",
  "TASK_URM.card.archive" = "c_TaskURM.cf_Archive",
  "TASK_URM.card.restore" = "c_TaskURM.cf_RestoreArchive",
  "TASK_URM.card.edit_sample" = "c_TaskURM.cf_EditSample",
  "TASK_URM.card.add_device" = "c_TaskURM.cf_AddDevice",
  "TASK_URM.card.add_group" = "c_TaskURM.cf_AddGroup",
  "TASK_URM.card.delete_device" = "c_TaskURM.cf_DeleteDevice",
  "TASK_URM.card.delete_group" = "c_TaskURM.cf_DeleteGroup",
  "TASK_URM.card.cancel" = "c_TaskURM.cf_CancelTask",
  "TASK_URM.card.repeat" = "c_TaskURM.cf_RepeatTask",
  // Журнал событий от АРМ
  "ARM_EVENT_LOG" = "g_EventLogARM",
  // Настройки агентов
  "ADMIN.ARM_PROPERTIES" = "g_ArmProperties",
  "ADMIN.ARM_PROPERTIES.card" = "c_ArmProperties",
  "ADMIN.ARM_PROPERTIES.POLICY" = "c_ArmProperties.ct_Policy",
  "ADMIN.ARM_PORPERTIES.INVENTORY" = "c_ArmProperties.ct_Inventory",
  "ADMIN.ARM_PROPERTIES.card.add" = "c_ArmProperties.cf_PolicyAdd",
  "ADMIN.ARM_PROPERTIES.card.edit" = "c_ArmProperties.cf_PolicyEdit",
  "ADMIN.ARM_PROPERTIES.card.set_device" = "c_ArmProperties.cf_PolicySetDevice",
  "ADMIN.ARM_PROPERTIES.card.activate" = "c_ArmProperties.cf_PolicyActivate",
  "ADMIN.ARM_PROPERTIES.card.restore" = "c_ArmProperties.cf_PolicyRestore",
  "ADMIN.ARM_PROPERTIES.card.archive" = "c_ArmProperties.cf_PolicyArchive",
  "ADMIN.ARM_PORPERTIES.sync" = "c_ArmProperties.cf_Send",
  // Расширенная инвентаризация (реестр Windows)
  "ADMIN.ARM_PORPERTIES.INVENTORY.WINDOWS_REGISTRY_KEYS.add" = "c_ArmProperties.cf_InventoryAdd",
  "ADMIN.ARM_PORPERTIES.INVENTORY.WINDOWS_REGISTRY_KEYS.edit" = "c_ArmProperties.cf_InventoryEdit",
  "ADMIN.ARM_PORPERTIES.INVENTORY.WINDOWS_REGISTRY_KEYS.delete" = "c_ArmProperties.cf_InventoryDelete",
  // Безопастность
  "ADMIN.SECURITY" = "g_Security",
  "ADMIN.SECURITY.CARD" = "c_Security",
  "ADMIN.SECURITY.SAVE" = "c_Security.cf_Save",
  "ADMIN.SECURITY.AGENT_KEYS.APPROVE_KEY" = "c_Security.cf_CertApprove",
  "ADMIN.SECURITY.AGENT_KEYS.ARCHIVE_KEY" = "c_Security.cf_RootCertArchive",
  "ADMIN.SECURITY.AGENT_KEYS.BLOCKED_KEY" = "c_Security.cf_CertBlock",
  "ADMIN.SECURITY.AGENT_KEYS.UNLOCK_KEY" = "c_Security.cf_CertUnBlock",
  "ADMIN.SECURITY.AGENT_KEYS.REISSUE_KEY" = "c_Security.cf_CertReissue",
  "ADMIN.SECURITY.AGENT_KEYS.DOWNLOAD_KEY" = "c_Security.cf_RootCertDownloadFile",
  "ADMIN.SECURITY.AGENT_KEYS.ROOT_CERT_DISTRIBUTE" = "c_Security.cf_RootCertDistribute",
  "ADMIN.SECURITY.AGENT_KEYS.ROOT_CERT_DOWNLOAD" = "c_Security.cf_RootCertDownload",
  "ADMIN.SECURITY.AGENT_KEYS.ROOT_CERT_DOWNLOAD_PUBLIC_KEY" = "c_Security.cf_RootCertDownloadPublicKey",
  "ADMIN.SECURITY.AGENT_KEYS.ROOT_CERT_DOWNLOAD_PRIVATE_KEY" = "c_Security.cf_RootCertDownloadPrivateKey",
  "ADMIN.SECURITY.AGENT_KEYS.ROOT_CERT_GENERATE" = "c_Security.cf_RootCertGenerate",
  "ADMIN.SECURITY.AGENT_KEYS.ROOT_CERT_CREATE_CSR" = "c_Security.cf_RootCertCreateCSR",
  "ADMIN.SECURITY.AGENT_CONFIGURATION.edit" = "c_Security.cf_AgentConfigurationSave",
  "ADMIN.SECURITY.SERVER_KEYS.APPROVE_KEY" = 'c_Security.cf_CertValidate',
  // О системе
  "ADMIN.ABOUT_SYSTEM" = 'g_SystemInfo',
}

export enum RoleType {
  COMMON = "COMMON",
  PERSONAL = "PERSONAL",
  NO_ROLE = "NO_ROLE",
}

export enum CardRegimes {
  ADD = 'add',
  EDIT = 'edit',
  VIEW = 'view',
}

export enum TaskScheduleType {
  DATABASE = "DATABASE",
  BACKEND = "BACKEND",
}

export enum Microservice {
  MAIN = "WS_WEB_BACKEND",
}

export enum ArmStateCode {
  CREATED = "CREATED", // Создан
  NEW = "NEW", // Новый
  ACTIVE = "ACTIVE", // Активен
  ARCHIVE = "ARCHIVE", // Архив
  BLOCKED = "BLOCKED", // Заблокирован
  WAITING_CONFIRMATION = "WAITING_CONFIRMATION", // Ожидает подтверждения
}

export enum ArmConnectStateCode {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
}
export enum EArmConnectionStateIcon {
  ONLINE = "icon-online",
  OFFLINE = "icon-offline",
}

export enum ArmStateIcon {
  CREATED = "icon-new", // Создан
  NEW = "icon-new", // Новый
  ACTIVE = "icon-good", // Активен
  ARCHIVE = "icon-in_archive", // Архив
  BLOCKED = "icon-denied", // Заблокирован
  WAITING_CONFIRMATION = 'icon-in_wait' // Ожидает подтверждения
}

export enum TaskSampleStateCode {
  NEW = "NEW", // Новый
  DRAFT = "DRAFT", // Черновик
  ACTIVE = "ACTIVE", // Активен
  ARCHIVE = "ARCHIVE", // Архив
}

export enum TaskSampleStateIcon {
  NEW = "icon-new", // Новый
  DRAFT = "icon-registered", // Черновик
  ACTIVE = "icon-good", // Активен
  ARCHIVE = "icon-in_archive", // Архив
}

export enum TaskTerminalCommand {
  RESULT = "TASK_ANSIBLE_RESULT",
  CANCEL = "TASK_ANSIBLE_FINISH",
}

export enum TaskScenarioDiagramSettingCode {
  PRODUCT_ID = "PRODUCT_ID",
  SCRIPT_PARAMETER = "SCRIPT_PARAMETER",
  FILE = "FILE",
}

export enum TaskUrmStateCode {
  CREATED = "CREATED", // Новый
  TASK_DRAFT = "TASK_DRAFT", // Черновик
  TASK_ACTIVE = "TASK_ACTIVE", // Активен
  TASK_ARCHIVE = "TASK_ARCHIVE", // Архив
  TASK_INACTIVE = "TASK_INACTIVE", // Неактивна
}

export enum TaskUrmStateIcon {
  CREATED = "icon-new", // Новый
  TASK_DRAFT = "icon-registered", // Черновик
  TASK_ACTIVE = "icon-good", // Активен
  TASK_ARCHIVE = "icon-in_archive", // Архив
  TASK_INACTIVE = "icon-dismantled_state", // Неактивна
}

export enum TaskUrmScheduleType {
  ONCE = "ONCE",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
}

export enum TaskUrmJournalStateCode {
  CREATED = "CREATED", // Создана
  TASK_LOG_WAIT = "TASK_LOG_WAIT", // Ожидает выполнения
  TASK_LOG_IN_QUEUE = "TASK_LOG_IN_QUEUE", // В очереди на выполнение
  TASK_LOG_IN_PROGRESS = "TASK_LOG_IN_PROGRESS", // Выполняется
  TASK_LOG_COMPLETE = "TASK_LOG_COMPLETE", // Выполнена
  TASK_LOG_PARTIALLY_COMPLETE = "TASK_LOG_PARTIALLY_COMPLETE", // Выполнена частично
  TASK_LOG_ERROR = "TASK_LOG_ERROR", // Ошибка
  TASK_LOG_CANCEL = "TASK_LOG_CANCEL", // Отменена
}

export enum TaskUrmJournalStateIcon {
  CREATED = "icon-registered", // Создана
  TASK_LOG_WAIT = "icon-in_wait", // Ожидает выполнения
  TASK_LOG_IN_QUEUE = "icon-in_queue", // В очереди на выполнение
  TASK_LOG_IN_PROGRESS = "icon-service", // Выполняется
  TASK_LOG_COMPLETE = "icon-good", // Выполнена
  TASK_LOG_PARTIALLY_COMPLETE = "icon-success_with_error", // Выполнена частично
  TASK_LOG_ERROR = "icon-error_warn", // Ошибка
  TASK_LOG_CANCEL = "icon-dismantled_state", // Отменена
}

/**
 * Статусы команды агенту.
 */
export enum AgenCommandStateCode {
  CREATED = "CREATED",
  WAIT = "WAIT",
  DONE = "DONE",
  ERROR = "ERROR",
  CANCEL = "CANCEL",
  SEND = "SEND"
}

/**
 * Иконки статусов команд агенту.
 */
export enum AgenCommandStateIcon {
  CREATED = "icon-registered",
  WAIT = "icon-in_wait",
  DONE = "icon-good",
  ERROR = "icon-error_warn",
  CANCEL = "icon-dismantled_state",
  SEND = "icon-send"
}

/**
 * Статусы установщика агентов.
 */
export enum AgentInstallerStateCode {
  AG_INS_DRAFT = "AG_INS_DRAFT",
  AG_INS_ACTIVE = "AG_INS_ACTIVE",
  AG_INS_INVALID = "AG_INS_INVALID",
  AG_INS_ERROR = "AG_INS_ERROR",
  AG_INS_ARCHIVE = "AG_INS_ARCHIVE",
}

/**
 * Иконки статусов установщика агентов.
 */
export enum AgentInstallerStateIcon {
  AG_INS_DRAFT = "icon-registered",
  AG_INS_ACTIVE = "icon-good",
  AG_INS_INVALID = "icon-dismantled_state",
  AG_INS_ERROR = "icon-error_warn",
  AG_INS_ARCHIVE = "icon-in_archive",
}

/**
 * Типы операционных систем.
 */
export enum OperatingSystemTypeCode {
  WINDOWS = "WINDOWS",
  LINUX = "LINUX",
  MACOS = "MACOS",
}

/**
 * Иконки типов операционных систем.
 */
export enum OperatingSystemTypeIcon {
  WINDOWS = "icon-windows",
  LINUX = "icon-linux",
  MACOS = "icon-macos",
}

/**
 * Модели безопасности установщика агентов
 */
export enum AgentInstallerSecurityModel {
  PASSWORD = 'PASSWORD',
  MTLS = 'MTLS'
}

export enum WorkstationsPolicyState {
  CREATED = "CREATED",
  WORKSTATION_POLICY_DRAFT = "WORKSTATION_POLICY_DRAFT",
  WORKSTATION_POLICY_ACTIVE = "WORKSTATION_POLICY_ACTIVE",
  WORKSTATION_POLICY_ARCHIVE = "WORKSTATION_POLICY_ARCHIVE",
}

/**
 * Ветка реестра ключа Windows
 */
export enum WindowsRegistryKeyBranchCode {
  HKEY_CLASSES_ROOT = "HKEY_CLASSES_ROOT",
  HKEY_CURRENT_USER = "HKEY_CURRENT_USER",
  HKEY_LOCAL_MACHINE = "HKEY_LOCAL_MACHINE",
  HKEY_USERS = "HKEY_USERS",
  HKEY_CURRENT_CONFIG = "HKEY_CURRENT_CONFIG"
}

export enum WorkstationsPolicyStateIcon {
  WORKSTATION_POLICY_ARCHIVE = 'icon-in_archive',
  WORKSTATION_POLICY_ACTIVE = 'icon-good',
  WORKSTATION_POLICY_DRAFT = 'icon-registered',
}

export enum CertificateStateCode {
  CREATED = 'CREATED', // Создан
  CERT_ARCHIVE = 'CERT_ARCHIVE', // Архив
  CERT_BLOCKED = 'CERT_BLOCKED', // Заблокирован
  CERT_APPROVED = 'CERT_APPROVED', // Утвержден
  CERT_APPROVAL = 'CERT_APPROVAL' // Не утвержден
}
