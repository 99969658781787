import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ContentTypeInterceptor implements HttpInterceptor {

  private readonly targets: string[] = [
    '/task/template/package/upload',
    '/admin/file/upload',
    '/admin/ansible-file/upload',
    '/file/update',
    '/admin/agent/installer/source-file/upload',
    '/certificate/root/upload',
    '/certificate/server/public/upload',
    '/certificate/server/private/upload'
  ];

  constructor() { }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.needDeleteContentType(req.url)) {
      req = this.deleteContentType(req);
    }
    return next.handle(req);
  }

  private needDeleteContentType(url: string): boolean {
    return this.targets.some(t => url.includes(t));
  }

  private deleteContentType(req: HttpRequest<any>): HttpRequest<any> {
    let headers = new HttpHeaders({
      'Authorization': req.headers.get('Authorization')
    });
    return req.clone({ headers });
  }
}
