import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EButtonStyle } from 'web-frontend-component-library/components/button';

@Component({
  selector: 'app-save-buttons',
  templateUrl: './save-buttons.component.html',
  styleUrls: ['./save-buttons.component.scss']
})
export class SaveButtonsComponent {
  @Input() saveTitle = this.translateService.instant('GENERAL.SAVE');
  @Input() cancelTitle = this.translateService.instant('GENERAL.CANCEL');

  @Input() disabled: boolean;
  @Input() saveDisabled: boolean;
  @Input() cancelDisabled: boolean;

  @Output() clickSave = new EventEmitter<void>();
  @Output() clickCancel = new EventEmitter<void>();

  readonly EButtonStyle = EButtonStyle;

  constructor(
    private translateService: TranslateService
  ) { }
}
