import type { TEnvironment } from "./environment-type";

export const environment: TEnvironment = {
  production: false,
  AUTH_USERNAME: 'client_web',
  AUTH_PASSWORD: '&Xk?3dqcT=',
  AUTH_URL: window.edmSecurity,
  API_URL: window.edmBackend,
  WEBSOCKET_URL: window.edmWebSocket,
  REMOTE_DESKTOP_SCHEME: window.remoteDesktopScheme
};
