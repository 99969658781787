import { Observable, of } from "rxjs";
import {
  ISelectItem,
  IDictionaryField
} from "web-frontend-component-library/interfaces";
import { UniversalGridUtilsService } from 'web-frontend-component-library/grid/services/universal-grid-utils'
import { DictionaryApiService } from "@core/services/api/dictionary-api.service";
import { TranslateService } from "@ngx-translate/core";
import { share } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { DateTimeService, DictionaryService } from "web-frontend-component-library/services";
import { FilterType } from "web-frontend-component-library/enums";

@Injectable()
export class EdmUniversalGridUtilsService extends UniversalGridUtilsService {

  constructor(
    private dictionaryApiService: DictionaryApiService,
    private dictionaryService: DictionaryService,
    protected translateService: TranslateService,
    protected dateTimeService: DateTimeService
  ) {
    super(translateService, dateTimeService);
  }

  public getDictionaryType(dictCode: string): FilterType.select | FilterType.simpleDictionary {
    return [
      'WORKSTATION_EVENT_TYPE',
      'OS_TYPE'
    ]
      .includes(dictCode)
      ? FilterType.select
      : FilterType.simpleDictionary;
  }

  public getSelectList(dictCode: string): Observable<ISelectItem[]> {
    let result: Observable<ISelectItem[]> = of([]);
    switch (dictCode) {
      case 'WORKSTATION_EVENT_TYPE': {
        result = this.dictionaryApiService.getWorkstationEventTypeDictionary();
        break;
      }
      case 'OS_TYPE': {
        result = this.dictionaryApiService.getOsTypeList();
        break;
      }
    }
    return result.pipe(share());
  }

  public getDictionary(dictCode: string): IDictionaryField {
    let result: IDictionaryField = null;
    switch (dictCode) {
      case 'INVENTORY_TYPE': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getInventoryTypeDictionary(params),
          {},
        );
        break;
      }
      case 'INVENTORY_TREE': {
        result = this.dictionaryService.getDefaultDictionary(
          (params) => this.dictionaryApiService.getInventoryTreeDictionary(params),
          {},
        );
        break;
      }
    }
    return result;
  }
}
