<div *ngIf="functionsForView" class="RoleFunctionsBlockClass">
  <div *ngFor="let func of functionsForView" class="role-functions-item">
    <lib-switch [value]="func.isChecked"
                [title]="func.name"
                (OnChange)="changeValue($event, func)"
                name="{{func.code}}"
                class="role-functions-item-switch"
                [withLockIcon]="false"
                [readonly]="readonly || func.disabled"
                [disabled]="readonly || func.disabled"
    ></lib-switch>
  </div>
</div>
