<div class="TaskResultModalClass">
  <div class="modal-header">{{title}}</div>
  <div class="modal-body">
    <div class="task-result-modal-content">
    <div class="task-result-modal-content-body scroll-block">
      <div class="task-result-modal-messages">
        <div *ngFor="let message of messages"
             class="task-result-modal-message">{{message}}</div>
      </div>
    </div>
    </div>
  </div>
  <div class="modal-footer">
    <lib-button class="modal-footer-btn task-result-modal-footer-btn"
                (OnClick)="cancel()"
                [title]="'GENERAL.CLOSE' | translate"
    ></lib-button>
  </div>
</div>
