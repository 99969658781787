
<lib-button
  class="white"
  [title]="cancelTitle"
  [disabled]="!disabled || cancelDisabled"
  (OnClick)="clickCancel.emit()"
></lib-button>

<lib-button
  class="save-button"
  [title]="saveTitle"
  [buttonStyles]="[EButtonStyle.FILLED]"
  [disabled]="!disabled || saveDisabled"
  (OnClick)="clickSave.emit()"
></lib-button>
