import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from "@angular/router";
import { Location } from "@angular/common";
import { AuthService } from "@core/services/auth/auth.service";
import { UserService } from "@core/services/user.service";
import {IUserMenu} from "web-frontend-component-library/interfaces";
import {MenuService} from 'web-frontend-component-library/services';
import {Observable} from "rxjs";
import {filter, map} from "rxjs/operators";

@Injectable()
export class AuthGuard implements CanActivateChild {

  constructor(
    private router: Router,
    private location: Location,
    private authService: AuthService,
    private userService: UserService,
    private menuService: MenuService
  ) { }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.canActivate(childRoute, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!this.authService.isAuthorized()) {
      document.location.href = this.authService.getAuthCodeUrl(document.location.href);
      return false;
    }

    return this.userService.userInfo$.pipe(
      filter(data => !!data),
      map(userInfo => {
        const userMenu: IUserMenu[] = this.menuService.getUserMenuValue();
        if (!userInfo || !userInfo.roles || !userMenu || userMenu.length === 0) {
          this.navigateToNoAccess(state.url);
          return false;
        }
        return true;
      }));
  }

  private navigateToNoAccess(url: string): void {
    this.router.navigate(['/no-access']).then(() => {
      this.location.replaceState(url);
    });
  }
}
