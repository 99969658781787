import { AbstractType, InjectionToken, Injector, NgModule, Type } from '@angular/core';
import { showToastFinalize } from '@shared/functions/show-toast';

/**
 * Сервис для использования инжектора с помощью которого можно получить зависимости вне компонентов.
 * Например {@link showToastFinalize}
 *
 * @example
 * const service = InjectorContainerModule.get(TranslateService);
 */
@NgModule()
export class InjectorContainerModule {
  private static injector: Injector;

  constructor(injector: Injector) {
    InjectorContainerModule.injector = injector;
  }

  static get<T>(token: Type<T> | InjectionToken<T> | AbstractType<T>): T {
    return InjectorContainerModule.injector.get<T>(token);
  }
}
