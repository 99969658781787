import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from "@core/services/auth/auth.service";
import { Location } from "@angular/common";
import { VisibilityService } from "web-frontend-component-library/services";

@Injectable()
export class AccessGuardService implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private visibilityService: VisibilityService,
    private location: Location
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    //в userAccessObjects передаём значения из enum USER_ACCESS
    const userAccessObjects: string[] = route.data['userAccessObjects'];

    if (this.authService.isAuthorized()) {
      return this.hasAnyAccessForActivate(userAccessObjects, state.url);
    } else {
      document.location.href = this.authService.getAuthCodeUrl(document.location.href);
      return false;
    }
  }

  private hasAnyAccessForActivate(userAccessObjects: string[], url: string): boolean {
    const canActivate: boolean = userAccessObjects && userAccessObjects.length > 0
      && this.visibilityService.isVisibleAny(userAccessObjects);
    if (!canActivate) {
      this.navigateToNoAccess(url);
    }
    return canActivate;
  }

  private navigateToNoAccess(url: string): void {
    this.router.navigate(['/no-access']).then(() => {
      this.location.replaceState(url);
    });
  }
}
