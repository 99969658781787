import { takeUntil } from 'rxjs/operators';
import { tap, finalize } from 'rxjs/operators';
import { DownloadStatesService, UtilsService } from 'web-frontend-component-library/services';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpEventType,
  HttpParams
} from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { IFileName } from 'web-frontend-component-library/interfaces';

@Injectable()
export class DownloadStatesInterceptor implements HttpInterceptor {

  constructor(
    private downloadStatesService: DownloadStatesService,
    private utilsService: UtilsService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.reportProgress) {
      const key = request.url + Date.now().toString();
      const cancel$ = new Subject<any>();
      const value$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
      const fileName: IFileName = this.utilsService.getFileNameWithExtension(
        request.params.get('displayFileName')
      );
      request = this.removeFileNameParam(request);
      this.downloadStatesService.addDownload({
        key,
        value: value$,
        fileName: !!fileName?.name
          ? fileName
          : null,
        cancel$,
        type: request.responseType === 'blob' ? 'download' : 'upload'
      });
      return next.handle(request).pipe(
        tap((event: HttpEvent<any>) => {
          if ((request.responseType === 'blob' && event.type === HttpEventType.DownloadProgress) ||
            (request.responseType === 'json' && event.type === HttpEventType.UploadProgress)
          ) {
            this.downloadStatesService.updateValue(
              key,
              Math.round(event.loaded / event.total * 100)
            );
          }
        }),
        finalize(() => this.downloadStatesService.removeDownload(key)),
        takeUntil(cancel$)
      );
    }
    else {
      return next.handle(request);
    }
  }

  private removeFileNameParam(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const newParams = {};
    request.params.keys()
      .filter(k => k !== 'displayFileName')
      .forEach(k => {
        newParams[k] = request.params.get(k);
      });
    return request.clone({
      params: new HttpParams({ fromObject: newParams })
    });
  }
}
